import React, { useState } from "react";
import "../styling/Menu.css";

const Menu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleNavClick = (id: string) => {
    document.getElementById(id)?.scrollIntoView();
  };

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className="hamburger" onClick={toggleMenu}>
        {menuOpen ? "✖" : "☰"}
      </div>
      <nav className={menuOpen ? "open" : ""}>
        <button
          className="menu-button"
          onClick={() => {
            handleNavClick("");
            toggleMenu();
          }}
        >
          Home
        </button>
        <button
          className="menu-button"
          onClick={() => {
            handleNavClick("about-section");
            toggleMenu();
          }}
        >
          About
        </button>
        <button
          className="menu-button"
          onClick={() => {
            handleNavClick("experience-section");
            toggleMenu();
          }}
        >
          Experience
        </button>
        <button
          className="menu-button"
          onClick={() => {
            handleNavClick("skills-section");
            toggleMenu();
          }}
        >
          Skills
        </button>
      </nav>
    </>
  );
};

export default Menu;
