import "./App.css";
import Header from "./components/Header";
import WorkExperience from "./components/WorkExperience";
import Skills from "./components/Skills";
import AboutMe from "./components/AboutMe";
import Menu from "./components/Menu";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Menu />
      <Header />
      <AboutMe />
      <WorkExperience />
      <Skills />
      <Footer />
    </div>
  );
}

export default App;
