import React from "react";
import "../styling/Skills.css";

const Skills: React.FC = () => {
  return (
    <section id="skills-section">
      <div className="title-bar">
        <h2>SKILLS</h2>
        <img src="../../solutions-architect.png" alt="Solutions Architect Associate Certification" />
        <img src="../../dev-associate.png" alt="AWS Developer Associate Certification" />
        <img src="../../cloud-practitioner.png" alt="AWS Cloud Practitioner Certification" />
      </div>

      <div className="skills-container">
        <div className="skills-category">
          <h3>Cloud</h3>
          <ul>
            <li>Cloud Computing (AWS, GCP)</li>
            <li>Serverless (Lambda, DynamoDB etc.)</li>
            <li>Security (IAM)</li>
            <li>Compute (EC2, ECS, Fargate)</li>
            <li>CI/CD (Github Actions, CodePipeline)</li>
          </ul>
        </div>

        <div className="skills-category">
          <h3>Methodology</h3>
          <ul>
            <li>Agile/Kanban/Scrum</li>
            <li>CI/CD</li>
            <li>Unit Testing</li>
            <li>MVVM</li>
            <li>IaC</li>
            <li>Version Control</li>
            <li>Cross-Platform Development</li>
          </ul>
        </div>

        <div className="skills-category">
          <h3>Languages</h3>
          <ul>
            <li>TypeScript</li>
            <li>JavaScript</li>
            <li>Python</li>
            <li>Dart</li>
            <li>Swift</li>
            <li>HTML/CSS</li>
          </ul>
        </div>

        <div className="skills-category">
          <h3>Frameworks / Dev</h3>
          <ul>
            <li>Flutter</li>
            <li>SwiftUI</li>
            <li>React</li>
            <li>Git/Github</li>
            <li>Jira</li>
            <li>VSCode</li>
            <li>XCode</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Skills;
