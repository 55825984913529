import React from "react";
import "../styling/Footer.css";

// You can import logos or use a CDN for images/icons
// For example, using Font Awesome icons:
// <i className="fab fa-github"></i>
// <i className="fab fa-linkedin"></i>

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="contact-info">
        <a href="mailto:bendavie1999@gmail.com" className="github">
          <img src="../../gmail.png" alt="Github" />
        </a>
        <a
          href="https://github.com/bendavie"
          target="_blank"
          rel="noopener noreferrer"
          className="github"
        >
          <img src="../../github.png" alt="Github" />
        </a>
        <a
          href="https://linkedin.com/in/bendavie1"
          target="_blank"
          rel="noopener noreferrer"
          className="linkedin"
        >
          <img src="../../linkedin.png" alt="LinkedIn" />
        </a>
      </div>
      <button className="footer-button" onClick={() => window.scrollTo(0, 0)}>Return to Top</button>
    </footer>
  );
};

export default Footer;
