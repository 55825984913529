import React from "react";
import "../styling/WorkExperience.css";

const WorkExperience: React.FC = () => {
  return (
    <section id="experience-section">
      <h2>WORK EXPERIENCE</h2>

      <div className="job-entry">
        <h3>DevOps Engineer</h3>
        <p className="company">Dropkick Development Ltd - 2021-Ongoing</p>
        <ul>
          <li>
            Developed robust CI/CD pipelines, optimizing the building, testing,
            and deployment of code for timely and efficient release cycles.
          </li>
          <li>
            Successfully migrated clients to serverless architectures, achieving
            significant savings in compute costs and streamlining operational
            processes.
          </li>
          <li>
            Wrote clean, efficient, and testable code ensuring maintainability
            and smooth integration; excelled in TypeScript and Python.
          </li>
          <li>
            Collaborated in a dynamic environment using both Scrum and Kanban
            methodologies, enhancing team agility and responsiveness.
          </li>
          <li>
            Demonstrated expertise in version control, consistently managing
            codebases with Git to ensure seamless collaboration and code
            integrity.
          </li>
          <li>
            Engaged in comprehensive unit testing, guaranteeing the reliability
            and stability of software releases.
          </li>
          <li>
            Strategized and steered a Solana Tokenisation project, showcasing an
            adept understanding of blockchain technologies in a DevOps context.
          </li>
          <li>
            Spearheaded containerisation strategies using Docker and Kubernetes,
            enhancing application scalability and simplifying deployment
            processes.
          </li>
          <li>
            Implemented state management solutions in Flutter applications,
            ensuring responsive and scalable mobile apps.
          </li>
          <li>
            Collaborated with cross-functional teams to optimise AWS costs,
            utilising tools like AWS Cost Explorer and implementing budget
            alerts.
          </li>
          <li>
            Leveraged APIs for integrations with both Android and iOS platforms,
            ensuring application robustness and efficiency.
          </li>
          <li>
            Worked with UX/UI designers, translating mockups and wireframes into
            high-quality code seamlessly.
          </li>
        </ul>
      </div>

      <div className="job-entry">
        <h3>Web Designer/Developer</h3>
        <p className="company">Destined Media - 2020-2021</p>
        <ul>
          <li>
            Expertly built Wix and Shopify websites with integrated booking and
            e-commerce functionalities, with full ownership over the project.
          </li>
          <li>
            Achieved a 50% increase in sales through effective website design
            and integration.
          </li>
          <li>
            Implemented strategic design solutions that boosted conversion rates
            by 15%.
          </li>
          <li>
            Successfully optimised website performance, achieving load times
            below 3 seconds and enhancing user experience.
          </li>
          <li>
            Collaborated in a dynamic team of designers, ensuring efficient
            project execution.
          </li>
          <li>
            Integrated SEO best practices, resulting in a 25% increase in
            organic traffic and improved search engine rankings.
          </li>
          <li>
            Adopted Kanban work methods, enhancing workflow efficiency and team
            productivity.
          </li>
          <li>
            Demonstrated proficiency in both individual and team environments,
            consistently delivering results above expectations.
          </li>
          <li>
            Engaged in continuous professional development, attending web design
            conferences, workshops, and webinars to stay updated with industry
            trends.
          </li>
          <li>
            Utilised various design tools, including Adobe Creative Suite
            (Photoshop, Illustrator, XD) and Figma, to create visually appealing
            and functional web elements.
          </li>
        </ul>
      </div>
    </section>
  );
};

export default WorkExperience;
