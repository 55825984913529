import React from 'react';
import '../styling/Header.css'

const Header: React.FC = () => {
    return (
        <header className="header">
            <h1 >BEN DAVIE RESUME</h1>
            <h2 className="subheading">A LONDON BASED DEVOPS ENGINEER</h2>
        </header>
    );
}

export default Header;