import '../styling/AboutMe.css';

const AboutMe: React.FC = () => {
    return (
        <section id="about-section">
            <div className="about-content">
                <img src="../../about-me.jpeg" alt="Your Name" className="about-image" />
                <div className="about-text">
                    <h2>ABOUT ME</h2>
                    <p>
                    I'm originally from New Zealand and began my coding journey in 2018. Self-taught, I've worked on projects from building Wix websites to delving into serverless architectures. Now based in London, I pride myself on my self-motivation, problem-solving skills, continuous learning, and time management. My experiences have equipped me with the skills necessary to take on diverse tech challenges.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default AboutMe;
